import { type Colors } from '@loveholidays/design-system';
import React, { ReactElement, useRef } from 'react';
import { SxStyleProp } from 'theme-ui';

import { Container as ContainerType, ContentWidth, SpacingY } from '@AuroraTypes';
import { pageContainerStyles } from '@UX/Page/LegacyPageContainer';

const spacingXStyles: Record<ContentWidth, SxStyleProp> = {
  default: pageContainerStyles(true),
  fullWidth: {},
  copy: {
    ...pageContainerStyles(true),
    width: [null, null, 'copymaxwidth'],
  },
  contentImageDefault: {
    ...pageContainerStyles(true),
    width: [null, null, 'copymaxwidth'],
    margin: [null, null, 'auto'],
  },
  contentImageWide: {
    ...pageContainerStyles(true),
    maxWidth: [null, null, 960],
    margin: [null, null, 'auto'],
  },
};

const spacingYStyles: Record<SpacingY, SxStyleProp> = {
  none: {},
  default: {
    marginY: '3xl',
  },
  content: {
    marginY: ['2xl', null, '3xl'],
  },
  bottom: {
    paddingBottom: '3xl',
  },
};

function getDisplayProperty(val: NonNullable<ContainerType['breakpointDisplay']>[number]) {
  if (typeof val === 'undefined' || val === null) return null;

  return val ? 'block' : 'none';
}

export const Container: React.FC<ContainerType> = ({
  id,
  contentWidth,
  spacingY,
  children,
  breakpointDisplay,
  backgroundColor,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const cloned = React.cloneElement(children as ReactElement, { containerRef });
  const needWrapper = !!backgroundColor && contentWidth !== 'fullWidth';
  const content = (
    <div
      id={needWrapper ? undefined : id}
      sx={{
        ...spacingXStyles[contentWidth],
        ...spacingYStyles[spacingY],
        ...(breakpointDisplay ? { display: breakpointDisplay.map(getDisplayProperty) } : {}),
        ...(backgroundColor &&
          !needWrapper && {
            backgroundColor: backgroundColor as keyof Colors,
          }),
      }}
      ref={containerRef}
    >
      {cloned}
    </div>
  );

  if (needWrapper) {
    return (
      <div
        id={id}
        sx={{
          backgroundColor: backgroundColor as keyof Colors,
        }}
      >
        {content}
      </div>
    );
  }

  return content;
};
