import React, { Fragment } from 'react';

import { GenericLayoutComponent, SectionChildren } from '@AuroraTypes';
import { Container } from '@Components/Container/Container';
import { LazyComponent } from '@Components/LayoutComponents/LazyComponent';
import { useGenericPageStore } from '@Stores/StoreContext';

type GenericLayoutComponentWithChildren = GenericLayoutComponent & {
  children?: SectionChildren;
};

export const GenericPageSection: React.FC<{ sectionId?: string }> = ({ sectionId }) => {
  const pageLayout = useGenericPageStore(({ pageContent }) => pageContent.pageLayout);

  const section = pageLayout.sections.find((s) => s.id === (sectionId || 'root'));

  if (!section) {
    return null;
  }

  return (
    <Fragment>
      {section.components.map((sectionComponent, index) => {
        const component = pageLayout.components.find((c) => c.id === sectionComponent.id);

        if (!component) {
          return null;
        }

        const { children, ...props } = component as GenericLayoutComponentWithChildren;

        return (
          <Container
            key={sectionComponent.container.id || sectionComponent.id}
            {...sectionComponent.container}
          >
            <LazyComponent
              id=""
              index={index}
              {...props}
            >
              {children?.sectionId ? (
                <GenericPageSection sectionId={children.sectionId} />
              ) : (
                children
              )}
            </LazyComponent>
          </Container>
        );
      })}
    </Fragment>
  );
};
